import { Url } from "@common/config/url-builder";
import { Section } from "@components/UI-Elements/General";
import Title from "@components/UI-Elements/Typography/Title";
import * as React from "react";
import CoinList from "../components/Modules/dashboard/coin-table";
import Button from "../components/UI-Elements/Button";
import Card from "../components/UI-Elements/Card";

type Props = {
  title?: string;
  coins: string[];
  coingeckoId?: string;
  descriptionTitle?: string;
  description?: string;
};
const CategoryTemplate: React.FC<Props> = ({ title, coins, description, descriptionTitle, coingeckoId, children }) => {
  const href = `https://www.coingecko.com/en/categories/${coingeckoId}`
  return (
    <>
      <Title>{title}</Title>
      <div className="grid gap-4">
        {description && (
          <Section>
            <Card title={descriptionTitle ? descriptionTitle : `📄 Was sind ${title}?`}>
              <p className="pb-2">
                {description}
              </p>
            </Card>
          </Section>
        )}
        {children}
      </div>
      <Section>
        <Title level={2}>Welche Kryptowährungen zählen zu dieser Kategorie?</Title>
        <CoinList coins={coins} />
        <div className="flex justify-between mt-3">
          <Button to={Url.knowledge.categories}>Zurück zur Übersicht</Button>
          {coingeckoId && <Button href={href} external>Weitere Kryptowährungen auf Coingecko</Button>}
        </div>
      </Section>
    </>
  );
};
export default CategoryTemplate;
