import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import CategoryTemplate from "../../../templates/category-page";

const coins = [
  "binancecoin",
  "crypto-com-chain",
  "ftx-token",
  "okb",
  "kucoin-shares",
  "huobi-token",
  "gatechain-token",
  "link",
  "hotbit-token",
  "bitpanda-ecosystem-token"
];

const title = "Zentrale Börsen (CEX)";
const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["zentrale börsen", "cex"]} title={title} />
      <CategoryTemplate title={title} coins={coins} coingeckoId="centralized-exchange-token-cex" />
    </Layout>
  );
};

export default Page;
